<template>
  <div class="selectKind">
    <div class="search">
      <div class="wrap">
        <input
          v-model="val"
          type="text"
          @input="input"
          placeholder="请输入要搜索的产品"
        />
        <span @click="onConfim(null)">确定</span>
      </div>
    </div>
    <div class="list st-wrapper">
      <div
        class="st-item"
        v-for="(item, index) in list"
        :key="index"
        @click="onConfim(item.goods_name)"
      >
        {{ item.goods_name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [],
      oldList: [],
      val: ''
    }
  },
  created () {
    const query = this.$route.query
    if (query.shopId) {
      this.getGoods(query.shopId, query.type)
    }
  },
  methods: {
    input () {
      let list = this.oldList
      list = list.filter(item => {
        return item.goods_name.indexOf(this.val) !== -1
      })
      this.list = list
    },
    getGoods (shopId, type) {
      this.$api.entry.goods({ seller_id: shopId, type: type, page_no: 1, page_size: 999 }).then(res => {
        // console.log(res.data)
        this.oldList = this.list = res.data.data
      })
    },
    onConfim (val) {
      // console.log(val || this.val)
      const query = this.$route.query
      const name = query.type === 'meat' ? 'EntryMeat' : 'EntryVegetables'
      const sourceGoods = this.$store.state.sourceGoods
      sourceGoods.source_goods_name = val || this.val
      this.$store.dispatch('setSourceGoods', sourceGoods)
      this.$router.push({
        name: name,
        query: {
          index: query.index,
          sourceGoodsName: val || this.val
        }
      })
    }
  }
}
</script>

<style lang="less" scoped >
@import './index.less';
</style>
